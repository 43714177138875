@font-face {
  font-family: "Desolator";
  src: local("Desolator-Bold"),
    url("../fonts/Desolator-Bold.otf") format("truetype");
}
@font-face {
  font-family: "LatoBold";
  src: local("Lato-Bold"), url("../fonts/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../fonts/Lato-Regular.ttf") format("truetype");
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
